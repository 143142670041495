import React from "react";
import VisibilitySensor from "react-visibility-sensor";

export default function RefreshSensor(props) {
  const { hasReachedEnd, ConcatIssues } = props;

  return hasReachedEnd ? (
    <div
      style={{
        height: "48px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      暂无更多期刊……
    </div>
  ) : (
    <VisibilitySensor
      partialVisibility={true}
      onChange={(isVisible) => {
        if (isVisible) {
          ConcatIssues();
        }
      }}
    >
      <div
        style={{
          height: "48px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        正在加载更多期刊……
      </div>
    </VisibilitySensor>
  );
}
