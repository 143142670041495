import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "Pages/Header";
import RefreshSensor from "./RefreshSensor";

function IssueButton(props) {
  const { id, title, coverImage, time, vol, conversations } = props;
  const navigate = useNavigate();

  return (
    <li class="col-12 col-md-8">
      <div class="card">
        <div class="img">
          <a
            onClick={() => {
              navigate(`/issue/${id}`);
            }}
          >
            <img src={"/api/public/" + coverImage} alt="" />
          </a>
        </div>
        <div class="time">
          <b>→ {vol}</b>
          <time>{time}</time>
        </div>
        <h3>
          {title.split(" ").map((i) => {
            return (
              <>
                {i}
                <br />
              </>
            );
          })}
        </h3>
        <div class="line"></div>
        <div class="txt">feat.</div>
        <div class="txt">
          {conversations.map((conversation) => {
            return <span>{conversation.name + " "}</span>;
          })}
        </div>
        <a
          onClick={() => {
            navigate(`/issue/${id}`);
          }}
          class="d-none d-sm-flex more"
        >
          READ now <i class="t">→</i>
        </a>
      </div>
    </li>
  );
}

export default function IssueOverview() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [hasReachedEnd, setReachedEnd] = useState(false);

  function GetHeight(i) {
    // console.log(i);
  }

  function GetIssues() {
    Refresh();
    axios
      .get(`/api/issue`)
      .then(function (res) {
        // console.log(res);
        if (res.data.success) {
          setData(res.data.data.rows);
          if (res.data.data.count > res.data.data.rows.length) {
            setReachedEnd(false);
          } else {
            setReachedEnd(true);
          }
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  function Refresh() {
    let _refreshCounter = refreshCounter - 1;
    setRefreshCounter(_refreshCounter);
  }

  function ConcatIssues() {
    let _page = page + 1;
    setPage(_page);
    axios
      .get(`/api/issue`, {
        params: {
          current: page,
        },
      })
      .then(function (res) {
        // console.log(res);
        if (res.data.success) {
          if (res.data.data.rows.length == 0) {
            setReachedEnd(true);
            return;
          } else {
            let newData = data.concat(res.data.data.rows);
            setData(newData);
            if (res.data.data.rows.length <= 6) {
              setReachedEnd(true);
            }
          }
        } else {
          throw res;
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  useEffect(() => {
    GetIssues();
  }, []);

  return (
    <div>
      <Header GetHeight={GetHeight} />
      <section class="container-fluid uc-issueOverview">
        <div class="container">
          <ul class="row list">
            {data.map((issue) => {
              return (
                <IssueButton
                  key={issue.id}
                  id={issue.id}
                  title={issue.title}
                  coverImage={issue.coverImage}
                  time={issue.time}
                  vol={issue.vol}
                  conversations={issue.conversations}
                  issue={issue}
                />
              );
            })}
          </ul>
        </div>
      </section>
      <RefreshSensor
        hasReachedEnd={hasReachedEnd}
        ConcatIssues={ConcatIssues}
      />
    </div>
  );
}
