import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useViewport from "Utils/UseViewport";
import parseJSONSafely from "Utils/ParseJSONSafely";
import logo from "Assets/logo.png";

export default function Header(props) {
  const { GetHeight } = props;
  const navigate = useNavigate();
  const { height: windowHeight, width: windowWidth } = useViewport();
  const canvasRef = useRef();
  const [xsMode, setXsMode] = useState(false);
  const [majorColor, setMajorColor] = useState("#b81c22");

  function ItemButton(props) {
    const { linkName, txt1, txt2, xsMode } = props;
    const location = useLocation();
    return (
      <li
        className={location.pathname.split("/")[1] === linkName ? "on" : null}
      >
        <a
          className="item"
          onClick={() => {
            navigate("/" + linkName);
          }}
        >
          <i className="txt1">{xsMode ? txt2 : txt1}</i>
          <i className="txt2">→ {txt2}</i>
        </a>
      </li>
    );
  }

  function GetHomeConfig() {
    axios
      .get(`/api/config`)
      .then(function (res) {
        if (res.data.success) {
          var colors = parseJSONSafely(res.data.data.color);
          if (colors.parseSuccess) {
            setMajorColor(colors.major);
          }
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  useEffect(() => {
    GetHeight(canvasRef.current.clientHeight);
    if (windowWidth < 768) {
      setXsMode(true);
    } else {
      setXsMode(false);
    }
  }, [canvasRef, windowHeight, windowWidth]);

  useEffect(() => {
    // if (bgColor) {
    //   setMajorColor(bgColor);
    // } else {
    //   setMajorColor("#b81c22");
    // }
    GetHomeConfig();
  }, []);

  return (
    <header
      className="container-fluid"
      ref={canvasRef}
      style={{ "--color": majorColor }}
    >
      <div className="container">
        <a
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logo} alt="" />
        </a>
        <ul className="list">
          <ItemButton
            linkName={"issue"}
            txt1={"ISSUE OVERVIEW"}
            txt2={"期刊总览"}
            xsMode={xsMode}
          />
          <ItemButton
            linkName={"infoUpdate"}
            txt1={"INFO UPDATES"}
            txt2={"更新日志"}
            xsMode={xsMode}
          />
          <ItemButton
            linkName={"aboutUS"}
            txt1={"ABOUT US"}
            txt2={"关于我们"}
            xsMode={xsMode}
          />
        </ul>
      </div>
    </header>
  );
}
