import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import useViewport from "Utils/UseViewport";
import Header from "Pages/Header";
import iconTop from "Assets/icon-top.png";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

export default function Detail() {
  const navigate = useNavigate();
  const location = useLocation();
  const canvasRef = useRef();
  const { width: windowWidth, height: windowHeight } = useViewport();
  const [pdfWidth, setPdfWidth] = useState(864);
  const [boxHeight, setBoxHeight] = useState(windowHeight - 450);

  const [data, setData] = useState({
    id: 1,
    title: "",
    subtitle: "",
    time: "",
    vol: "",
    via: "",
    reporter: "",
    editor: "",
    coverImage: {},
    pages: {},
    createdAt: "",
    updatedAt: "",
    conversations: [
      {
        id: 1,
        name: "",
        desc: "",
        file: "",
        via: "",
        reporter: "",
        editor: "",
        time: "",
        title: "",
      },
      {
        id: 2,
        name: "",
        desc: "",
        file: "",
        via: "",
        reporter: "",
        editor: "",
        time: "",
        title: "",
      },
      {
        id: 3,
        name: "",
        desc: "",
        file: "",
        via: "",
        reporter: "",
        editor: "",
        time: "",
        title: "",
      },
    ],
  });
  const [currentCon, setCurrentCon] = useState(0);
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    GetIssue();
  }, []);

  useEffect(() => {
    if (windowWidth <= 864) {
      setPdfWidth(windowWidth - 40);
    } else {
      setPdfWidth(864);
    }
  }, [windowWidth]);

  function ChangeCon(index) {
    setCurrentCon(index);
  }

  function GetHeight(x) {
    var y = canvasRef.current.clientHeight;
    setBoxHeight(windowHeight - x - y);
  }

  function GetIssue() {
    var id = location.pathname.split("/")[2];
    axios
      .get(`/api/issue/${id}`)
      .then(function (res) {
        // console.log(res);
        if (res.data.success) {
          setData(res.data.data);
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  function BackToTop() {
    document.querySelector(".react-pdf__Page").scrollIntoView();
  }

  return (
    <div>
      <Header GetHeight={GetHeight} />
      <section class="container-fluid uc-detail">
        <div class="container">
          <div class="title-box" ref={canvasRef}>
            <div class="time-box">
              <b>→ {data.vol}</b>
              <time>{data.time}</time>
              <div class="name">
                <b class="uc-big">{data.title}</b>
              </div>
              <a
                onClick={() => {
                  navigate(`/issue/${data.id}/book`, {
                    state: { issue: data },
                  });
                }}
                class="d-none d-sm-block btn"
              >
                切换
              </a>
            </div>
            <div class="filter">
              {data.conversations.map((data, index) => (
                <a
                  class={index === currentCon ? "item on" : "item"}
                  onClick={() => {
                    ChangeCon(index);
                  }}
                >
                  <h3>{data.name}</h3>
                  <p>{data.desc}</p>
                </a>
              ))}
            </div>
            <div class="title">
              <h2>{data.conversations[currentCon]?.title}</h2>
              <ul class="info">
                <li>
                  <span class="txt">受访时间</span>
                  {data.conversations[currentCon]?.time.slice(0, -3)}
                </li>
                <li>
                  <span class="txt">
                    <i class="e05">采</i>
                    <i class="e05">访</i>者
                  </span>
                  {data.conversations[currentCon]?.reporter}
                </li>
                <li>
                  <span class="txt">采访媒介</span>
                  {data.conversations[currentCon]?.via}
                </li>
                <li>
                  <span class="txt">稿件编辑</span>
                  {data.conversations[currentCon]?.editor}
                </li>
              </ul>
            </div>
          </div>
          <div class="scroll-box" style={{ height: boxHeight }}>
            <Document
              file={"/api/public/" + data.conversations[currentCon]?.file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  id={`page_${index + 1}`}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderTextLayer={true}
                  renderAnnotationLayer={false}
                  width={pdfWidth}
                />
              ))}
            </Document>
          </div>
        </div>
      </section>

      <a
        onClick={() => {
          BackToTop();
        }}
        class="g-goTop js_goTop d-none d-sm-block"
      >
        <img src={iconTop} alt="" />
      </a>
    </div>
  );
}
