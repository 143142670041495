import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import HomePage from "Pages/Home";
import IssueOverview from "Pages/IssueOverview";
import Detail from "Pages/Detail";
import Book from "Pages/Book";
import InfoUpdate from "Pages/InfoUpdate";
import AboutUs from "Pages/AboutUs";
import "./main.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/issue" element={<IssueOverview />} />
      <Route path="/issue/:id" element={<Detail />} />
      <Route path="/issue/:id/book" element={<Book />} />
      <Route path="/infoUpdate" element={<InfoUpdate />} />
      <Route path="/aboutUS" element={<AboutUs />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
