import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Header from "Pages/Header";
import bookArrowLeft from "Assets/book-arrow-left.png";
import bookArrowRight from "Assets/book-arrow-right.png";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

export default function Book() {
  const navigate = useNavigate();
  const location = useLocation();
  const canvasRef = useRef();
  const [pdfWidth, setPdfWidth] = useState(824 / 2);

  const [data, setData] = useState({
    id: 1,
    title: "",
    subtitle: "",
    time: "",
    vol: "",
    via: "",
    reporter: "",
    editor: "",
    coverImage: {},
    pages: "",
    createdAt: "",
    updatedAt: "",
    conversations: [
      {
        id: 1,
        name: "",
        desc: "",
        file: "",
      },
      {
        id: 2,
        name: "",
        desc: "",
        file: "",
      },
      {
        id: 3,
        name: "",
        desc: "",
        file: "",
      },
    ],
  });

  useEffect(() => {
    GetIssue();
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function GetHeight(i) {
    // console.log(i);
  }

  useEffect(() => {
    var w = canvasRef.current.clientWidth;
    setPdfWidth(w / 2);
  }, [canvasRef]);

  function GetIssue() {
    var id = location.pathname.split("/")[2];
    axios
      .get(`/api/issue/${id}`)
      .then(function (res) {
        // console.log(res);
        if (res.data.success) {
          setData(res.data.data);
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  return (
    <div>
      <Header GetHeight={GetHeight} />
      <section class="container-fluid uc-book">
        <div class="container">
          <div class="title-box">
            <div class="time-box">
              <b>→ {data.vol}</b>
              <time>{data.time}</time>
              <div class="name">
                <b class="uc-big">{data.title}</b>
              </div>
              <a
                onClick={() => {
                  navigate(`/issue/${data.id}`, {
                    state: { issue: data },
                  });
                }}
                class="d-none d-sm-block btn"
              >
                切换
              </a>
            </div>
          </div>
        </div>
        <div class="swiper-box">
          <div class="book-img" ref={canvasRef}>
            <Document
              file={"/api/public/" + data.pages}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Page
                  pageNumber={pageNumber}
                  renderTextLayer={true}
                  renderAnnotationLayer={false}
                  width={pdfWidth}
                />
                <Page
                  pageNumber={pageNumber + 1}
                  renderTextLayer={true}
                  renderAnnotationLayer={false}
                  width={pdfWidth}
                />
              </div>
            </Document>
          </div>
          <div
            class="book-btn book-btn-prev"
            onClick={() => {
              setPageNumber(pageNumber - 2);
              if (pageNumber <= 1) setPageNumber(1);
            }}
          >
            <img src={bookArrowLeft} alt="" />
          </div>
          <div
            class="book-btn book-btn-next"
            onClick={() => {
              setPageNumber(pageNumber + 2);
              if (pageNumber >= numPages) setPageNumber(numPages);
            }}
          >
            <img src={bookArrowRight} alt="" />
          </div>
        </div>
      </section>
    </div>
  );
}
