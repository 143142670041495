import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import useViewport from "Utils/UseViewport";
import parseJSONSafely from "Utils/ParseJSONSafely";
import Header from "Pages/Header";
import RefreshSensor from "./RefreshSensor";

function InfoLayer(props) {
  const { title, content, href, time, xsMode } = props;
  const canvasRef = useRef();

  function SlideToggle() {
    $(canvasRef.current).toggleClass("on").find(".detail").slideToggle();
  }

  return (
    <li ref={canvasRef}>
      <div class="row title-box">
        <div
          class="col-5 col-sm-7 left"
          style={xsMode ? { display: "flex", flexDirection: "column" } : null}
        >
          <time style={{ width: "60px" }}>NEW!</time>
          <span>{time.split("T")[0]}</span>
        </div>
        <div class="col-19 col-sm-17 right">
          <div class="name">{title}</div>
          {!xsMode && (
            <div class="more-box">
              {content === null ? null : (
                <a
                  onClick={() => {
                    SlideToggle();
                  }}
                  class="r"
                >
                  long post
                </a>
              )}
              {href === null ? null : (
                <a href={"https://" + href} class="more">
                  →
                </a>
              )}
            </div>
          )}
        </div>
      </div>
      {content === null ? null : (
        <div class="row detail-box">
          <div class="col-5 col-sm-7"></div>
          <div class="col-19 col-sm-17">
            <div class="detail">
              <div class="con">
                <p>{content}</p>
              </div>
              {href === null ? null : (
                <a href={"https://" + href} class="more">
                  →
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </li>
  );
}

export default function InfoUpdate() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [hasReachedEnd, setReachedEnd] = useState(false);
  const [xsMode, setXsMode] = useState(false);
  const [minorColor, setMinorColor] = useState("#f1ec4a");
  const { height: windowHeight, width: windowWidth } = useViewport();

  useEffect(() => {
    if (windowWidth < 768) {
      setXsMode(true);
    } else {
      setXsMode(false);
    }
  }, [windowHeight, windowWidth]);

  function GetHeight(i) {
    // console.log(i);
  }

  function GetTheme() {
    axios
      .get(`/api/config`)
      .then(function (res) {
        if (res.data.success) {
          var colors = parseJSONSafely(res.data.data.color);
          if (colors.parseSuccess) {
            setMinorColor(colors.minor);
          }
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  function GetInfo() {
    Refresh();
    axios
      .get(`/api/info`)
      .then(function (res) {
        // console.log(res);
        if (res.data.success) {
          setData(res.data.data.rows);
          if (res.data.data.count > res.data.data.rows.length) {
            setReachedEnd(false);
          } else {
            setReachedEnd(true);
          }
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  function Refresh() {
    let _refreshCounter = refreshCounter - 1;
    setRefreshCounter(_refreshCounter);
  }

  function ConcatInfos() {
    let _page = page + 1;
    setPage(_page);
    axios
      .get(`/api/info`, {
        params: {
          current: page,
        },
      })
      .then(function (res) {
        // console.log(res);
        if (res.data.success) {
          if (res.data.data.rows.length == 0) {
            setReachedEnd(true);
            return;
          } else {
            let newData = data.concat(res.data.data.rows);
            setData(newData);
            if (res.data.data.rows.length <= 10) {
              setReachedEnd(true);
            }
          }
        } else {
          throw res;
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  useEffect(() => {
    GetTheme();
    GetInfo();
  }, []);

  return (
    <div>
      <Header GetHeight={GetHeight} />
      <section
        class="container-fluid g-titleBox"
        style={{ "--color": minorColor }}
      >
        <div class="container hasArrow">
          <h2>
            内容
            <br />
            更新日志
          </h2>
          <div class="txt">不定期更新企划最新动态</div>
        </div>
      </section>
      <section class="container-fluid uc-infoUpdates">
        <div class="container">
          <ul class="list">
            {data.map((item) => {
              return (
                <InfoLayer
                  title={item.title}
                  content={item.content}
                  href={item.href}
                  time={item.createdAt}
                  xsMode={xsMode}
                />
              );
            })}
          </ul>
        </div>
      </section>
      <RefreshSensor hasReachedEnd={hasReachedEnd} ConcatInfos={ConcatInfos} />
    </div>
  );
}
