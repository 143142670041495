import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "Pages/Header";
import parseJSONSafely from "Utils/ParseJSONSafely";

export default function AboutUs() {
  const [minorColor, setMinorColor] = useState("#f1ec4a");

  function GetHeight(i) {
    // console.log(i);
  }

  function GetTheme() {
    axios
      .get(`/api/config`)
      .then(function (res) {
        if (res.data.success) {
          var colors = parseJSONSafely(res.data.data.color);
          if (colors.parseSuccess) {
            setMinorColor(colors.minor);
          }
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  useEffect(() => {
    GetTheme();
  }, []);

  return (
    <div>
      <Header GetHeight={GetHeight} />
      <section
        class="container-fluid g-titleBox"
        style={{ "--color": minorColor }}
      >
        <div class="container hasArrow">
          <h2>
            关于
            <br />
            我们
          </h2>
          <div class="txt">一些有的没的</div>
        </div>
      </section>
      <section class="container-fluid uc-aboutUs">
        <div class="container">
          <ul class="list">
            <li>
              <h3>
                →<br />
                [来呔空]{" "}
              </h3>
              <div class="con">
                [来呔空] 是由Layla和TaiKOInaut
                组成的双人内容产出组合，试图探索自洽的内容输出与个人表达的方式。
              </div>
              <div class="links">
                <div class="item">
                  <span class="l">Weibo (TaiKOInaut) </span>
                  <a href="https://weibo.com/taikoinaut" class="more">
                    →
                  </a>
                </div>
                <div class="item">
                  <span class="l">小宇宙 (TuNa) </span>
                  <a
                    href="https://xiaoyuzhoufm.com/podcast/643fecbee8c70509b18ec12f"
                    class="more"
                  >
                    →
                  </a>
                </div>
              </div>
            </li>
            <li>
              <h3>
                →<br />
                有任何意见或问题{" "}
              </h3>
              <div class="links">
                <div class="item">
                  <span class="l">PUSHOONG提问箱 </span>
                  <a href="https://pushoong.com/ask/8767855440" class="more">
                    →
                  </a>
                </div>
                <div class="item">
                  <span class="l">POME提问箱(TaiKOInaut) </span>
                  <a href="https://pome.ink/TaiONi89" class="more">
                    →
                  </a>
                </div>
              </div>
            </li>
            <li>
              <h3>
                →<br />
                加入我们的企划{" "}
              </h3>
              <div class="con">
                如果您是原创内容创作者，愿意与我们聊聊您的创作与作品，可以填写下方问卷，非常期待能在合适的时机与您建立联系！
              </div>
              <div class="links">
                <div class="item">
                  <span class="l">TuNa问卷 </span>
                  <a href="https://wj.qq.com/s2/12156543/5fdf" class="more">
                    →
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}
