import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useViewport from "Utils/UseViewport";
import parseJSONSafely from "Utils/ParseJSONSafely";
import Header from "Pages/Header";

export default function HomePage() {
  const canvasRef = useRef();
  const navigate = useNavigate();
  const { height: windowHeight } = useViewport();
  const [refHeight, setRefHeight] = useState(330);
  const [boxHeight, setBoxHeight] = useState(windowHeight - 450);
  const [data, setData] = useState({
    id: 1,
    title: "",
    subtitle: "",
    time: "",
    vol: "",
    via: "",
    reporter: "",
    editor: "",
    coverImage: "",
    pages: {},
    createdAt: "",
    updatedAt: "",
    conversations: [
      {
        id: 1,
        name: "",
        desc: "",
        file: {},
      },
      {
        id: 2,
        name: "",
        desc: "",
        file: {},
      },
      {
        id: 3,
        name: "",
        desc: "",
        file: {},
      },
    ],
  });
  const [minorColor, setMinorColor] = useState("#f1ec4a");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [splashBG, setSplashBG] = useState("");

  function GetHeight(x) {
    setBoxHeight(windowHeight - x - refHeight);
  }

  function GetHomeIssue() {
    axios
      .get(`/api/issue`) // 拉最新的
      .then(function (res) {
        if (res.data.success) {
          setData(res.data.data.rows[0]);
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  function GetHomeConfig() {
    axios
      .get(`/api/config`)
      .then(function (res) {
        if (res.data.success) {
          var colors = parseJSONSafely(res.data.data.color);
          if (colors.parseSuccess) {
            setMinorColor(colors.minor);
          }
          setTitle(res.data.data.title);
          setContent(res.data.data.content);
          setSplashBG(res.data.data.splashBG);
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  useEffect(() => {
    GetHomeConfig();
    GetHomeIssue();
  }, []);

  useEffect(() => {
    setRefHeight(canvasRef.current.clientHeight);
  }, [canvasRef]);

  return (
    <div>
      <Header GetHeight={GetHeight} />
      <section
        class="container-fluid g-titleBox"
        ref={canvasRef}
        style={{ "--color": minorColor }}
      >
        <div class="container">
          {/* 当我朝九晚五
            <br />TODO:换行
            依旧在思考创作 */}
          {data.title.split(" ").map((i) => {
            return (
              <>
                <h2>{i}</h2>
              </>
            );
          })}

          <div class="txt">
            创作者访谈期刊企划
            <b>{data.vol}</b>
          </div>
          <a
            onClick={() => {
              navigate(`/issue/${data.id}`);
              //TODO:手机版点不到
            }}
            class="more"
          >
            READ now <i class="t">→</i>
          </a>
        </div>
      </section>
      <section class="container-fluid uc-home">
        <div class="d-sm-none img-phone">
          <img src={"/api/public/" + splashBG} alt="" />
        </div>
        <div class="container">
          <div class="d-none d-sm-block img-pc">
            <img src={"/api/public/" + splashBG} alt="" />
          </div>
          <div class="row">
            <div class="col-24 col-sm-11">
              <div class="scroll-box" style={{ height: boxHeight }}>
                <h3>
                  →<br />
                  {title}
                </h3>
                <pre class="con">{content}</pre>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
